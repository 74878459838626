import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { StoreApiService } from '../store-api.service';
import { SignupSidebarComponent } from './signup-sidebar/signup-sidebar.component';
import { environment } from '../../environments/environment';
import { components, operations } from '../../api-types/storeApiTypes';

export interface CustomerState {
  loggedIn: boolean;
  firstName: string | null;
  lastName: string | null;
  street: string | null;
  city: string | null;
  zipcode: string | null;
  phoneNumber: string | null;
  email: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerStateService {
  private readonly store = inject(StoreApiService);
  private sidebar: Signal<SignupSidebarComponent | undefined> | undefined;
  private state = signal<CustomerState>({
    loggedIn: false,
    firstName: null,
    city: null,
    phoneNumber: null,
    lastName: null,
    email: null,
    zipcode: null,
    street: null,
  });

  customerState = this.state.asReadonly();

  constructor() {
    this.isLoaded = this.reloadContext();
  }

  isLoaded: Promise<CustomerState>;

  firstName = computed(() => {
    const state = this.state();
    return state.firstName;
  });

  isLoggedOut = computed(() => {
    const state = this.state();
    return !state.loggedIn;
  });

  isLoggedIn = computed(() => {
    const state = this.state();
    return state.loggedIn;
  });

  private async reloadContext() {
    const ctx = await this.store.apiClient.invoke('readContext get /context');
    if (ctx.data.customer && !ctx.data.customer.guest) {
      const customerState: CustomerState = {
        loggedIn: true,
        firstName: ctx.data.customer.firstName,
        lastName: ctx.data.customer.lastName,
        street: ctx.data.customer.activeShippingAddress?.street ?? null,
        city: ctx.data.customer.activeShippingAddress?.city ?? null,
        zipcode: ctx.data.customer.activeShippingAddress?.zipcode ?? null,
        phoneNumber:
          ctx.data.customer.activeShippingAddress?.phoneNumber ?? null,
        email: ctx.data.customer.email,
      };
      this.state.set(customerState);
      return customerState;
    } else {
      const customerState: CustomerState = {
        loggedIn: false,
        firstName: null,
        lastName: null,
        street: null,
        city: null,
        zipcode: null,
        phoneNumber: null,
        email: null,
      };
      this.state.set(customerState);
      return customerState;
    }
  }

  recentOrders() {
    const data = signal<{
      loading: boolean;
      orders: components['schemas']['Order'][];
    }>({ loading: true, orders: [] });
    this.store.apiClient
      .invoke('readOrder post /order', {
        body: {
          associations: {
            deliveries: {},
            documents: {},
            lineItems: {
              associations: {
                cover: {},
              },
            },
          },
        },
      })
      .then((res) => {
        data.set({
          loading: false,
          orders: res.data.orders.elements,
        });
      });
    return data;
  }

  async logout() {
    await this.store.apiClient.invoke('logoutCustomer post /account/logout');
    await this.reloadContext();
  }

  async login(email: string, password: string) {
    await this.store.apiClient.invoke('loginCustomer post /account/login', {
      body: {
        username: email,
        password: password,
      },
    });
    await this.reloadContext();
  }

  async register(body: operations['register post /account/register']['body']) {
    await this.store.apiClient.invoke('register post /account/register', {
      body,
    });
    await this.reloadContext();
  }

  async ensureSignedUp(): Promise<false | { firstName: string }> {
    const ctx = await this.store.apiClient.invoke('readContext get /context');
    if (ctx.data.customer && !ctx.data.customer.guest) {
      return { firstName: ctx.data.customer.firstName };
    }

    if (!this.sidebar) {
      return false;
    }
    const sidebar = this.sidebar();
    if (!sidebar) {
      return false;
    }

    sidebar.open();

    return false;
  }

  async forgotPassword(email: string) {
    await this.store.apiClient.invoke(
      'sendRecoveryMail post /account/recovery-password',
      {
        body: {
          email,
          storefrontUrl: environment.storefrontUrl,
        },
      },
    );
  }

  async recoverPassword(hash: string, newPassword: string) {
    await this.store.apiClient.invoke(
      'recoveryPassword post /account/recovery-password-confirm',
      {
        body: {
          hash,
          newPassword,
          newPasswordConfirm: newPassword,
        },
      },
    );
    await this.reloadContext();
  }

  setSidebar(sidebar: Signal<SignupSidebarComponent | undefined>) {
    this.sidebar = sidebar;
  }
}
