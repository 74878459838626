<label
  class="@md:col-span-2"
  [ngClass]="{ 'text-gray-900': !showError(), 'text-red-600': showError() }"
>
  <input
    [formControl]="control()"
    type="checkbox"
    class="h-4 w-4 mr-2 rounded border-gray-300 text-primary focus:ring-primary"
  />
  <ng-content></ng-content>
</label>
