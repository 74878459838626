import { InputSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, map, startWith, switchMap } from 'rxjs';

export function showErrorForControl(controlInput: InputSignal<FormControl>) {
  return toSignal(
    toObservable(controlInput).pipe(
      switchMap((control) =>
        combineLatest([
          control.valueChanges.pipe(startWith({})),
          control.statusChanges.pipe(startWith({})),
        ]).pipe(
          map(() => {
            return control.touched && control.status === 'INVALID';
          }),
        ),
      ),
    ),
  );
}

export function showHintForControl(controlInput: InputSignal<FormControl>) {
  return toSignal(
    toObservable(controlInput).pipe(
      switchMap((control) =>
        combineLatest([
          control.valueChanges.pipe(startWith({})),
          control.statusChanges.pipe(startWith({})),
        ]).pipe(
          map(() => {
            return control.dirty && control.status === 'INVALID';
          }),
        ),
      ),
    ),
  );
}
