import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';

@Component({
    selector: 'app-header',
    imports: [ContainerComponent, RouterLink, FaIconComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  backUrl = input<string | string[] | null>(null);
  header = input.required<string>();

  protected readonly faArrowLeft = faArrowLeft;
}
