export const isApiClientError = (
  val: unknown,
): val is { details: { errors: { code: string; detail: string }[] } } =>
  !!val &&
  typeof val === 'object' &&
  'details' in val &&
  !!val.details &&
  typeof val.details === 'object' &&
  'errors' in val.details &&
  val['details'].errors instanceof Array;
