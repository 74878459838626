<app-container>
  <div class="flex items-center">
    @if (backUrl()) {
      <a
        [routerLink]="backUrl()"
        class="flex items-center space-x-3 md:space-x-0 md:hidden"
      >
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        <span class="text-gray-600">{{ header() }}</span>
      </a>
      <div class="hidden md:block">{{ header() }}</div>
    } @else {
      <span>
        {{ header() }}
      </span>
    }
  </div>
</app-container>
