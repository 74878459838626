<div class="grid grid-cols-1 gap-x-8 gap-y-10 pt-6 pb-6 @xl:grid-cols-3">
  <div>
    <h2 class="text-base font-semibold leading-7 text-gray-900">
      {{ header() }}
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-600">{{ description() }}</p>
  </div>

  <div class="flex flex-col max-w-2xl @xl:col-span-2">
    <ng-content></ng-content>
  </div>
</div>
