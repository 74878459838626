import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";

@Component({
    selector: 'app-select',
    imports: [
        ReactiveFormsModule
    ],
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent {
  label = input.required<string>();
  control = input.required<FormControl>();
}
