import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  InputSignal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl } from '@angular/forms';
import {
  showErrorForControl,
  showHintForControl,
} from '../input/showErrorForControl';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, map, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'app-input-label',
    imports: [NgClass],
    templateUrl: './input-label.component.html',
    styleUrl: './input-label.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputLabelComponent {
  label = input.required<string>();
  control = input.required<FormControl>();

  showHint = showHintForControl(this.control);
  showError = showErrorForControl(this.control);
  controlErrors = controlErrors(this.control);

  hint = computed(() => {
    const errors = this.controlErrors();
    const showHint = this.showHint();
    const showError = this.showError();

    if (!errors) {
      return '';
    }

    if (!showHint) {
      return '';
    }

    if (errors['minlength']) {
      if (showError) {
        return `Nur ${errors['minlength'].actualLength} von ${errors['minlength'].requiredLength} Zeichen`;
      } else {
        return `Mindestens ${errors['minlength'].requiredLength} Zeichen`;
      }
    }

    return '';
  });
}

export function controlErrors(controlInput: InputSignal<FormControl>) {
  return toSignal(
    toObservable(controlInput).pipe(
      switchMap((control) =>
        combineLatest([
          control.valueChanges.pipe(startWith({})),
          control.statusChanges.pipe(startWith({})),
        ]).pipe(
          map(() => {
            return control.errors;
          }),
        ),
      ),
    ),
  );
}
