import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'app-form-section',
    imports: [],
    templateUrl: './form-section.component.html',
    styleUrl: './form-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionComponent {
  header = input.required<string>();
  description = input.required<string>();
}
