import { FormGroup } from '@angular/forms';

export function ensureValidity(form: FormGroup, options: { scroll: boolean }) {
  if (!form.valid) {
    form.markAllAsTouched();
    form.updateValueAndValidity();

    for (const item of Object.values(form.controls)) {
      item.markAsTouched();
      item.markAsDirty();
      item.updateValueAndValidity();
    }

    console.log(
      'Form is not valid',
      Object.entries(form.controls)
        .filter((c) => !c[1].valid)
        .map((c) => ({
          errors: c[1].errors,
          name: c[0],
        })),
    );

    if (options.scroll) {
      document.scrollingElement?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    return false;
  }

  return true;
}
