import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  OnChanges,
  signal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-checkbox',
    imports: [FormsModule, ReactiveFormsModule, NgClass],
    templateUrl: './checkbox.component.html',
    styleUrl: './checkbox.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnChanges {
  destroyRef = inject(DestroyRef);

  control = input.required<FormControl>();

  showError = signal(false);

  ngOnChanges() {
    this.control()
      .statusChanges.pipe(startWith({}), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.showError.set(
          this.control().touched && this.control().status !== 'VALID',
        );
      });
  }
}
