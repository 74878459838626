import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { InputLabelComponent } from '../input-label/input-label.component';
import { showErrorForControl } from './showErrorForControl';

@Component({
    selector: 'app-input',
    imports: [ReactiveFormsModule, NgClass, InputLabelComponent],
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent {
  label = input.required<string>();
  type = input<string>('text');
  autocomplete = input<string>();
  control = input.required<FormControl>();

  showError = showErrorForControl(this.control);
}
