import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'app-container',
    imports: [],
    templateUrl: './container.component.html',
    styleUrl: './container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerComponent {
  padding = input<string>('px-6');
}
