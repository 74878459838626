import { inject, Injectable, makeStateKey } from '@angular/core';
import { StoreApiService } from './store-api.service';
import { ListState } from './listStore';
import { Schemas } from '../api-types/storeApiTypes';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  storeApi = inject(StoreApiService);

  store = this.storeApi.createListStore(
    makeStateKey<ListState<Schemas['Country']>>('country-list'),
    (signal) =>
      this.storeApi.apiClient
        .invoke('readCountry post /country', { fetchOptions: { signal } })
        .then((r) => ({
          elements: r.data.elements as Schemas['Country'][],
          total: r.data.total ?? 0,
        })),
  );
}
